<template>
	<div>
		<PageTitle title="药品物流" fixed backTo="/logistics"></PageTitle>
		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<List :width="100">
				<ListItem :leftWidth="0" :rightWidth="100">
					<template #top>
						<p>订单详情</p>
					</template>
					<template #right>
						<div>
							<p>订单状态：{{item.wlzt}}</p>
							<p>物流单号：{{item.kddh}}
								<button class="copy-btn" v-show="item.kddh != '暂无'" v-clipboard:copy="item.kddh"
									v-clipboard:success="onCopy" v-clipboard:error="onError">复制单号
								</button>
							</p>
							<p>订单编码：{{item.ddbm}}</p>
							<van-button v-show="item.ddbm" size="small" type="primary" @click="getLogisticInfo()" :loading="btnloading" loading-text="物流查询中..." >查看物流情况</van-button>
						</div>
						<div class="div-mt">
							<p>收货人：{{item.sjrxm}} {{item.phone}}</p>
							<p v-show="item.sjrxm != '来院自取'">
								收货地址：{{item.sjrsf}}&nbsp;{{item.sjrqs}}&nbsp;{{item.sjrxj}}&nbsp;{{item.sjrzj}}</p>
							<p v-show="item.sjrxm != '来院自取'">{{item.sjrxxdz}}</p>
						</div>
						<div class="div-mt">
							<p>执业点：{{item.ksmc}}</p>
							<p>开方医生：{{item.ygxm}}</p>
							<p>诊断：</p>
							<p v-for="(diagnosis,diagnosisIndex) in item.diagnosisList" :key="diagnosisIndex">
								{{diagnosisIndex + 1}}.{{diagnosis.jbmc}}
							</p>
						</div>
						<div class="div-mt">
							<p>药品名称：</p>
							<div class="div-mt-5" v-for="(drug,index) in item.ypxx1List" :key="index">
								<p>{{index + 1}}.{{drug.ypmc}} ({{drug.yfgg}}) 共{{drug.ypsl}}{{drug.yfdw}}</p>
								<p style="color: #76B49C;margin-left: 10px;">用法：{{drug.cfbz}}，{{drug.xmmc}}</p>
							</div>
						</div>
						<div class="div-mt width-half">
							<div>
								<p>挂号费：</p>
							</div>
							<div>
								<p>￥{{item.ghf}}元</p>
							</div>
							<div>
								<p>药品费用：</p>
							</div>
							<div>
								<p>￥{{item.totalFee}}元</p>
							</div>
							<div>
								<p>药师审核费：</p>
							</div>
							<div>
								<p>{{item.ysshf}}</p>
							</div>
							<div>
								<p>快递费（顺丰快递）：</p>
							</div>
							<div>
								<p>{{item.kdf}}</p>
							</div>
						</div>
						<div style="float: right">
							<p style="font-size: 14px">合计费用 ￥{{item.hjfy}}元</p>
						</div>
						<div style="margin-top: 30px">
							<p>开具时间：{{item.kfrq}}</p>
						</div>
					</template>
					<template #bottom>

					</template>
				</ListItem>
			</List>
		</div>
		<van-popup v-model="showPopup" position="bottom" :style="{ height: '80%' }">
			<van-steps direction="vertical" :active="0">
				<van-step v-for="(obj, index) in logistics" :key="index">
					<h3>【{{obj.accept_address}}】 {{$util.moment(obj.accept_totaltime).format('YYYY-MM-DD HH:mm')}}</h3>
					<p>{{obj.remark}}</p>
					<p v-show="index == 0">运单号：{{obj.mailno}}</p>
				</van-step>
			</van-steps>
		</van-popup>
	<!-- 	<van-loading style="margin-top: 100px;" size="24px" vertical v-if="showLoading" class="loading">物流查询中...
		</van-loading> -->
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		GetDrug
	} from '@/api/user.js';
	import {
		Login,
		GetSFOrderRoute
	} from '@/api/logistics.js'

	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
				item: {},
				btnloading: false,
				showPopup: false,
				logistics: [
					// {
					// 	"mailno": "SF1334445767694",
					// 	"accept_address": "深圳市",
					// 	"accept_date": "2021-04-14T00:00:00",
					// 	"remark": "在官网\"运单资料&签收图\",可查看签收人信息",
					// 	"opcode": "8000",
					// 	"accept_time": "16:35:51",
					// 	"accept_totaltime": "2021-04-14T16:35:51"
					// }, {
					// 	"mailno": "SF1334445767694",
					// 	"accept_address": "深圳市",
					// 	"accept_date": "2021-04-14T00:00:00",
					// 	"remark": "您的快件已签收，如有疑问请电联快递员【212，电话：+8618588259494】。疫情期间顺丰每日对网点消毒、快递员每日测温、配戴口罩，感谢您使用顺丰，期待再次为您服务。（主单总件数：1件）",
					// 	"opcode": "80",
					// 	"accept_time": "16:35:50",
					// 	"accept_totaltime": "2021-04-14T16:35:50"
					// }, {
					// 	"mailno": "SF1334445767694",
					// 	"accept_address": "深圳市",
					// 	"accept_date": "2021-04-14T00:00:00",
					// 	"remark": "快件在【深圳福田天安营业部】完成分拣,准备发往下一站",
					// 	"opcode": "30",
					// 	"accept_time": "16:34:05",
					// 	"accept_totaltime": "2021-04-14T16:34:05"
					// },
					// {
					// 	"mailno": "SF1334445767694",
					// 	"accept_address": "深圳市",
					// 	"accept_date": "2021-04-14T00:00:00",
					// 	"remark": "顺丰速运 已收取快件",
					// 	"opcode": "50",
					// 	"accept_time": "16:33:46",
					// 	"accept_totaltime": "2021-04-14T16:33:46"
					// }
				]
			}
		},
		mounted() {
			GetDrug(this.$route.params.treatmentNum).then(
				(result) => {
					this.item = result;
					this.item.kfrq = this.$util.moment(this.item.kfrq).format('YYYY-MM-DD HH:mm');
				},
				() => {}
			);
			this.setLogisticToken(0);
		},
		methods: {
			onCopy() {
				this.$toast("复制成功");
			},
			onError() {
				this.$toast("复制失败");
			},
			setLogisticToken(status = 0) {
				Login({
					userName: "knzy",
					password: "165109ca09a6815d2973edd229efe561",
				}).then((result) => {
					localStorage.setItem('logistics_token', result.length > 0 ? result[0].access_token : '');
					if (status == 1) {
						this.getLogisticInfo();
					}
					},
					() => {});
			},
			getLogisticInfo() {
				this.btnloading = true;
				GetSFOrderRoute(this.item.ddbm).then(
					(res) => {
						this.btnloading = false;
						if (res.length > 0) {
							this.logistics = res;
							this.showPopup = true;
						} else {
							this.$dialog.alert({
								title: '温馨提醒',
								message: '暂无物流信息',
							})
						}
					},
					(err) => {
						this.btnloading = false;
						if (err.resultStatus == '400') {
							this.$dialog.alert({
								title: '温馨提醒',
								message: err.msg,
							})
						} else if (err.resultStatus == '401') {
							this.setLogisticToken(1);
						} else {
							this.$dialog.alert({
								title: '温馨提醒',
								message: err,
							})
						}
					}
				);
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	.copy-btn {
		padding: 0 10px;
		color: #fff;
		background: #3f9775;
		border: 1px #3f9775;
		border-radius: 5px;
		margin-left: 10px;
	}

	.div-mt {
		margin-top: 10px;
	}

	.div-mt-5 {
		margin-top: 5px;
	}

	.width-half {
		div {
			width: 50%;
			display: inline-block;
		}

		div:nth-child(2n) p {
			float: right;
			color: #FF2600;
		}

		div:nth-child(2n+1) p {
			float: left;
		}
	}

	.infoContainer {
		padding: 10px;

		p {
			margin: 0;
		}

		.top p {
			text-align: center;
			margin: 0 0 10px;
		}

		.right {
			p {
				font-size: @font-size-sm;
			}
		}
	}
</style>
